
import {get,post} from "@/axios";

//订单页面
export function getOrderList(data) {
    return get('api/order/list', data);
}

export function setOrderStatus(data) {
    return post('api/order/setStatus', data);
}


export function exportOrders(data) {
    return post('api/order/export', data);
}
